<template>
  <b-sidebar id="sidebar" :visible="isSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop
    no-header no-close-on-backdrop right @shown="loadData" @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0 text-capitalize">
          {{ isAdd ? $t("users_app.add") : $t("users_app.edit") }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">


          <!-- Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group class="text-capitalize" :label="$t('users_app.name')" label-for="name">
              <b-form-input id="name" v-model="itemData.name" :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- First name -->
          <validation-provider #default="validationContext" name="firstname" rules="required">
            <b-form-group class="text-capitalize" :label="$t('users_app.first_name')" label-for="firstname">
              <b-form-input id="firstname" v-model="itemData.first_name" :state="getValidationState(validationContext)"
                trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Second name -->
          <validation-provider #default="validationContext" name="secondname" rules="required">
            <b-form-group class="text-capitalize" :label="$t('users_app.second_name')" label-for="secondname">
              <b-form-input id="secondname" v-model="itemData.second_name"
                :state="getValidationState(validationContext)" trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider #default="validationContext" name="email" rules="required">
            <b-form-group class="text-capitalize" :label="$t('users_app.email')" label-for="email">
              <b-form-input id="email" v-model="itemData.email" :state="getValidationState(validationContext)" trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone Number -->
          <validation-provider #default="validationContext" name="phonenumber" rules="required">
            <b-form-group class="text-capitalize" :label="$t('users_app.phone_number')" label-for="phonenumber">
              <b-form-input id="phonenumber" v-model="itemData.phone_number"
                :state="getValidationState(validationContext)" trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Router -->
          <validation-provider #default="validationContext" name="router">
            <b-form-group class="text-capitalize" :label="$t('users_app.router')" label-for="router">
              <v-select v-model="itemData.router.id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="routerOptions" :clearable="false" :reduce="(val) => val.value" input-id="router" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Particular signs -->
          <validation-provider #default="validationContext" name="particularsigns">
            <b-form-group class="text-capitalize" :label="$t('users_app.particular_signs')" label-for="particularsigns">
              <b-form-input id="particularsigns" v-model="itemData.senas_particulares"
                :state="getValidationState(validationContext)" trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Height -->
          <validation-provider #default="validationContext" name="height">
            <b-form-group class="text-capitalize" :label="$t('users_app.height')" label-for="height">
              <b-form-input id="height" v-model="itemData.estatura" :state="getValidationState(validationContext)"
                trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Complexion -->
          <validation-provider #default="validationContext" name="complexion">
            <b-form-group class="text-capitalize" :label="$t('users_app.complexion')" label-for="complexion">
              <v-select v-model="itemData.complexion.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="complexionOptions" :clearable="false" :reduce="(val) => val.value" input-id="complexion" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Lips -->
          <validation-provider #default="validationContext" name="lips">
            <b-form-group class="text-capitalize" :label="$t('users_app.lips')" label-for="lips">
              <v-select v-model="itemData.labios.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="labiosOptions" :clearable="false" :reduce="(val) => val.value" input-id="lips" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Nose -->
          <validation-provider #default="validationContext" name="nose">
            <b-form-group class="text-capitalize" :label="$t('users_app.nose')" label-for="nose">
              <v-select v-model="itemData.nariz.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="narizOptions" :clearable="false" :reduce="(val) => val.value" input-id="nose" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Eye size -->
          <validation-provider #default="validationContext" name="eyesize">
            <b-form-group class="text-capitalize" :label="$t('users_app.eye_size')" label-for="eyesize">
              <v-select v-model="itemData.tamano_ojos.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tamanoOjosOptions" :clearable="false" :reduce="(val) => val.value"
                input-id="id_tamano_ojos" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Skin -->
          <validation-provider #default="validationContext" name="skin">
            <b-form-group class="text-capitalize" :label="$t('users_app.skin')" label-for="skin">
              <v-select v-model="itemData.tez.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tezOptions" :clearable="false" :reduce="(val) => val.value" input-id="id_tez" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Hair type -->
          <validation-provider #default="validationContext" name="hairtype">
            <b-form-group class="text-capitalize" :label="$t('users_app.hair_type')" label-for="hairtype">
              <v-select v-model="itemData.tipo_cabello.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tipoCabelloOptions" :clearable="false" :reduce="(val) => val.value"
                input-id="id_tipo_cabello" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Age -->
          <validation-provider #default="validationContext" name="age">
            <b-form-group class="text-capitalize" :label="$t('users_app.age')" label-for="age">
              <v-select v-model="itemData.edad.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="edadOptions" :clearable="false" :reduce="(val) => val.value" input-id="id_edad" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Face -->
          <validation-provider #default="validationContext" name="facetype">
            <b-form-group class="text-capitalize" :label="$t('users_app.face_type')" label-for="face_type">
              <v-select v-model="itemData.tipo_cara.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tipoCaraOptions" :clearable="false" :reduce="(val) => val.value" input-id="id_face_type" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2 text-capitalize"
              type="submit">
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide"
              class="text-capitalize">
              {{ $t('actions.cancel') }}
            </b-button>

          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";



export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },
    routerOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {

    const complexionOptions = ref([{ value: 1, label: "Robusta" }, { value: 2, label: "Mediana" }, { value: 3, label: "Delgada" }]);
    const labiosOptions = ref([{ value: 1, label: "Gruesos" }, { value: 2, label: "Medianos" }, { value: 3, label: "Delgados" }])
    const narizOptions = ref([{ value: 1, label: "Recta" }, { value: 2, label: "Aguileña" }, { value: 3, label: "Respingada" }, { value: 4, label: "Chata" }])
    const tamanoOjosOptions = ref([{ value: 1, label: "Grandes" }, { value: 2, label: "Medianos" }, { value: 3, label: "Chicos" }])
    const tezOptions = ref([{ value: 1, label: "Clara" }, { value: 2, label: "Media" }, { value: 3, label: "Obscura" }])
    const tipoCaraOptions = ref([{ value: 1, label: "Cuadrada" }, { value: 2, label: "Ovalada" }, { value: 3, label: "Redonda" }])
    const tipoCabelloOptions = ref([{ value: 1, label: "Corto" }, { value: 2, label: "Mediano" }, { value: 3, label: "Largo" }])
    const edadOptions = ref([{ value: 1, label: "Joven" }, { value: 2, label: "Adulto Joven" }, { value: 3, label: "Adulto" }, { value: 4, label: "Adulto Mayor" }])
    const blankData = {
      name: "",
      first_name: "",
      second_name: "",
      email: "",
      phone_number: "",
      senas_particulares: "",
      estatura: "",
      id_complexion : null,
      id_labios: null,
      id_nariz: null,
      id_tamano_ojos: null,
      id_tez: null,
      id_tipo_cara: null,
      id_tipo_cabello: null,
      id_edad: null,
      id_router: null,
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {

      switch (itemData.value.complexion.description) {
        case "Robusta":
          itemData.value.complexion.description = 1
          break;
        case "Mediana":
          itemData.value.complexion.description = 2
          break;
        case "Delgada":
          itemData.value.complexion.description = 3
          break;

      }
      switch (itemData.value.labios.description) {
        case "Gruesos":
          itemData.value.labios.description = 1
          break;
        case "Medianos":
          itemData.value.labios.description = 2
          break;
        case "Delgados":
          itemData.value.labios.description = 3
          break;

      }
      switch (itemData.value.nariz.description) {
        case "Recta":
          itemData.value.nariz.description = 1
          break;
        case "Aguileña":
          itemData.value.nariz.description = 2
          break;
        case "Respingada":
          itemData.value.nariz.description = 3
          break;
        case "Chata":
          itemData.value.nariz.description = 4
          break;

      }
      switch (itemData.value.tamano_ojos.description) {
        case "Grandes":
          itemData.value.tamano_ojos.description = 1
          break;
        case "Medianos":
          itemData.value.tamano_ojos.description = 2
          break;
        case "Chicos":
          itemData.value.tamano_ojos.description = 3
          break;

      }
      switch (itemData.value.edad.description) {
        case "Joven":
          itemData.value.edad.description = 1
          break;
        case "Adulto Joven":
          itemData.value.edad.description = 2
          break;
        case "Adulto":
          itemData.value.edad.description = 3
          break;
        case "Adulto Mayor":
          itemData.value.edad.description = 4
          break;

      }
      switch (itemData.value.tez.description) {
        case "Clara":
          itemData.value.tez.description = 1
          break;
        case "Media":
          itemData.value.tez.description = 2
          break;
        case "Obscura":
          itemData.value.tez.description = 3
          break;

      }
      switch (itemData.value.tipo_cara.description) {
        case "Cuadrada":
          itemData.value.tipo_cara.description = 1
          break;
        case "Ovalada":
          itemData.value.tipo_cara.description = 2
          break;
        case "Redonda":
          itemData.value.tipo_cara.description = 3
          break;

      }
      switch (itemData.value.tipo_cabello.description) {
        case "Corto":
          itemData.value.tipo_cabello.description = 1
          break;
        case "Mediano":
          itemData.value.tipo_cabello.description = 2
          break;
        case "Largo":
          itemData.value.tipo_cabello.description = 3
          break;

      }

      const req = {
        id: itemData.value.ID,
        params: {
          name: itemData.value.name,
          first_name: itemData.value.first_name,
          second_name: itemData.value.second_name,
          email: itemData.value.email,
          phone_number: itemData.value.phone_number,
          senas_particulares: itemData.value.senas_particulares,
          estatura: itemData.value.estatura,

          id_complexion: itemData.value.complexion.description,
          id_labios: itemData.value.labios.description,
          id_nariz: itemData.value.nariz.description,
          id_tamano_ojos: itemData.value.tamano_ojos.description,
          id_edad: itemData.value.edad.description,

          id_tez: itemData.value.tez.description,
          id_tipo_cara: itemData.value.tipo_cara.description,
          id_tipo_cabello: itemData.value.tipo_cabello.description,
          id_router: itemData.value.router.id,
        }
      }
      console.log(itemData.value.router.id)

      store.dispatch("user_app/edit", req)
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
      }
    };

    return {

      itemData,
      onSubmit,
      complexionOptions,
      labiosOptions,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      narizOptions,
      tamanoOjosOptions,
      tezOptions,
      tipoCaraOptions,
      tipoCabelloOptions,
      edadOptions,

    };
  },
};
</script>